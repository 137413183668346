import { h } from 'preact';
import { Router } from 'preact-router';
import '../css/output.css'

// Code-splitting is automated for `routes` directory
import Diagrams from '../routes/diagrams/diagrams';

const App = () => (
	<div id="app">
		<main>
			<Router>
				<Diagrams path="models/:modelId/diagrams"  />
			</Router>
		</main>
	</div>
);

export default App;
