import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./base/Button";
import { h } from "preact";

interface DiagramPartCardProps {
  partId: number;
  orderTag?: string;
  name: string;
  imageUrl: string;
  description: string;
  oemNumber: string;
  skillLevel: number;
  isNla: boolean;
  inStock: boolean;
  originalOemNumber?: string;
  cartButtonText?: string;
  price?: number;
  bapgPartNumber?: string;
  onViewProductClick: (partId: string, bapgPartNumber: string) => void;
}

export default function DiagramPartCard(props: DiagramPartCardProps) {
  const {
    name,
    description,
    oemNumber,
    originalOemNumber,
    orderTag,
    price,
    bapgPartNumber,
    onViewProductClick
  } = props;

  const getPartPrice = (price?: number) => {
    if(!price)
      return null;

    return `$${price?.toFixed(2)}`;
  };

  return (
    <div>
      <div>
        <div>
          <div>
            <h3 className="text-xl font-bold mb-2">
              {orderTag ? `${orderTag}. ` : null}
              {name}
            </h3>
            <div className="mb-2 text-sm">
              <p>{description}</p>
              {originalOemNumber ? (
                <div className="italic py-2 px-3 my-2">
                  <small className="ml-1">
                    This part replaces {originalOemNumber}.
                  </small>
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-3 space-y-2 text-sm">
            <div className="inline-flex">OEM #: {oemNumber} </div>
            <div>
              Cost: <span className="ml-1">{getPartPrice(price)}</span>
            </div>
          </div>

          <div className="mt-4">
            <Button size="sm" onClick={() => onViewProductClick(oemNumber, bapgPartNumber)}>
              View Product
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
