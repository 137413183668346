import { FunctionalComponent, h } from "preact"
import { HTMLAttributes } from "preact/compat";
import { classNames } from "../../utils/general";

interface ButtonProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'size'>
{
    size?: 'sm';
}

export const SecondaryButton: FunctionalComponent<ButtonProps> = (props) => {
  const {size, ...rest} = props
  return  <button
    type="button"
    className={classNames(`w-full justify-center inline-flex items-center rounded-3xl border border-solid bg-white border-gray-300 px-8 py-3 text-lg font-medium text-[#001F3D] hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 hover:[-webkit-text-stroke:0.15px] disabled:bg-white disabled:text-gray-400 disabled:cursor-not-allowed`, props.size === 'sm' && 'px-5 py-2 text-sm' )}
    {...rest}
  >
    {props.children}
  </button>
  }

const Button: FunctionalComponent<ButtonProps> = (props) => {
  const {size, ...rest} = props
return   <button
  type="button"
  className={classNames("w-full justify-center inline-flex items-center rounded-3xl border border-transparent bg-[#0A5CC8] px-8 py-3 text-lg font-medium text-white hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 hover:[-webkit-text-stroke:0.15px] disabled:bg-[#D4DBE580] disabled:text-gray-400 disabled:cursor-not-allowed", props.size === 'sm' && 'px-5 py-2 text-sm')}
  {...rest}
>
  {props.children}
</button>
}

export default Button