import { FunctionalComponent, h } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus';
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons/faSearchMinus';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface ZoomImageProps {
  imageSrc: string;
}

const ZoomImage: FunctionalComponent<ZoomImageProps> = ({ imageSrc }) => {
  const DRAG_POWER = 1.5;
  const ZOOM_SIZE = 0.5;
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isDragging, setIsDragging] = useState(false);

  const touch = useRef({ x: 0, y: 0 });
  const imageRef = useRef<HTMLImageElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging) {
      return;
    }
    const { x, y } = touch.current;
    const { clientX, clientY } = event;

    const targetOffsetY = offset.y + (y - clientY) * DRAG_POWER;
    const targetOffsetX = offset.x + (x - clientX) * DRAG_POWER;

    setOffset(getRecalculatedOffset(targetOffsetX, targetOffsetY));
    touch.current = { x: clientX, y: clientY };
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    const { clientX, clientY } = event;
    touch.current = { x: clientX, y: clientY };
    setIsDragging(true);
  };

    const handleMouseUp = () => setIsDragging(false);

  const handleTouchStart = () => {
    disableBodyScroll(containerRef.current);
  };

  const handleTouchEnd = () => {
    enableBodyScroll(containerRef.current);
  };

  const draw = () => {
    if (!imageRef.current || !containerRef.current) return;
    imageRef.current.style.position = 'absolute';
    imageRef.current.style.cursor = 'grab';
    imageRef.current.style.left = `${(-offset.x).toString()}px`;
    imageRef.current.style.top = `${(-offset.y).toString()}px`;
    imageRef.current.width = containerRef.current.offsetWidth * zoom;
  };

  useEffect(() => {
    draw();
  }, [zoom, offset]);

  useEffect(() => {
    setOffset((targetOffset) => getRecalculatedOffset(targetOffset.x, targetOffset.y));
  }, [zoom]);

  const getRecalculatedOffset = (targetOffsetX: number, targetOffsetY: number) => {
    if (!imageRef.current || !containerRef.current) return { x: 0, y: 0 };

    const maxWidth = imageRef.current.width - containerRef.current.offsetWidth;
    const maxHeight = imageRef.current.height - containerRef.current.offsetHeight;

    return {
      x: targetOffsetX < 0 ? 0 : targetOffsetX > maxWidth ? maxWidth : targetOffsetX,
      y: targetOffsetY < 0 ? 0 : targetOffsetY > maxHeight ? maxHeight : targetOffsetY,
    };
  };

  const zoomIn = () => {
    setZoom((z) => z + ZOOM_SIZE);
  };

  const zoomOut = () => {
    setZoom((z) => (z > 1 ? z - ZOOM_SIZE : z));
  };

  return (
    <div className="w-full h-full relative overflow-hidden" ref={containerRef}>
      <div className="absolute left-2 top-2 flex flex-col gap-3 z-50">
        <button className="rounded-xl bg-[#0A5CC8] py-2 px-3 text-white" onClick={zoomIn}>
          <FontAwesomeIcon icon={faSearchPlus} />
        </button> 

        <button className="rounded-xl bg-[#0A5CC8] py-2 px-3 text-white" onClick={zoomOut}>
        <FontAwesomeIcon icon={faSearchMinus} />
        </button>
      </div>
      <img
        src={imageSrc}
        onPointerDown={handleMouseDown}
        onPointerUp={handleMouseUp}
        onPointerMove={handleMouseMove}
        onPointerLeave={() => setIsDragging(false)}
        ref={imageRef}
        role="presentation"
        alt="Diagram"
        className="max-w-none"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      />
    </div>
  );
};




export default ZoomImage;
