import { h, Fragment } from "preact";
import { useEffect } from "preact/hooks";
import useSWR, { SWRResponse } from "swr";
import { DiagramDto, GetPartPriceData, PartPrice } from "../dataLoaders/dto";
import DiagramPartCard from "./DiagramPartCard";
import ZoomImage from "./ZoomImage";

export interface DiagramViewProps {
  diagramId: number;
  partPrices : PartPrice [] | null;
  onGetPartPrices: (partsData : GetPartPriceData[]) => void;
  onViewProductClick: (partId: string, bapgPartNumber: string) => void;
}

const placeholderImage = `data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D`;

const SiteSettings = {
  RepairIntelligenceApiBaseUrl: process.env.RepairIntelligenceApiBaseUrl,
};

const getDiagramById = async (
  diagramId: number
): Promise<DiagramDto | undefined> => {
  const response = await fetch(
    `${SiteSettings.RepairIntelligenceApiBaseUrl}/diagrams/${diagramId}?api-version=v1`
  );
  if (!response.ok) return undefined;
  return (await response.json()) as DiagramDto;
};

export const useDiagramByIdLoader = (
  diagramId: number | null
): SWRResponse<DiagramDto | undefined> => {
  const key = diagramId === null ? null : `/diagrams/${diagramId}`;
  const swrResponse = useSWR<DiagramDto | undefined, Error>(key, () =>
    getDiagramById(diagramId ?? -1)
  );

  return swrResponse;
};

const DiagramView = ({ onGetPartPrices, onViewProductClick, diagramId, partPrices }: DiagramViewProps) => {
  const { data: diagram, isLoading } = useDiagramByIdLoader(diagramId);
  
  if (!isLoading && !diagram) {
    return <>Diagram failed to load.</>;
  }

  var partsString = JSON.stringify(diagram?.parts);
  useEffect(()=> {
    if(partsString && diagram?.parts) {
        const partsData = diagram.parts.map(c=>  { return { oemPartId : c.oem_number, bapgPartNumber : c.bapg_part_number } } );
        onGetPartPrices(partsData);
    }
  },[partsString]);

  return (
    <div className="mx-auto">
      <div
        className="grid grid-cols-12"
        style={{ height: "70vh", minHeight: "705px" }}
      >
        <div className="col-span-12 md:col-span-7 lg:col-span-8 p-2 h-full border border-gray-200 rounded-2xl">
          <ZoomImage imageSrc={diagram?.large_image_url ?? placeholderImage} />
          {isLoading ? (
            <div
              className="position-absolute top-50 start-50"
            />
          ) : null}
        </div>
        <div className="relative col-span-12 md:col-span-5 lg:col-span-4 h-full overflow-y-auto">
          <div className="absolute divide-y divide-gray-200 w-full">
            {diagram?.parts &&
              diagram.parts.map((part) =>  { 
                const partPrice = partPrices?.find(c=> c.bapgPartNumber == part.bapg_part_number);
                return (
                  <div key={part.part_id} className="py-8 px-8">
                    <DiagramPartCard
                      partId={part.part_id}
                      orderTag={part.order_tag}
                      name={part.title}
                      imageUrl={part.image_url}
                      description={part.description}
                      oemNumber={part.oem_number}
                      isNla={part.is_nla}
                      inStock={part.in_stock}
                      price={partPrice?.price}
                      skillLevel={part.skill_level || 0}
                      originalOemNumber={part.original_oem_number}
                      bapgPartNumber={part.bapg_part_number}
                      onViewProductClick={onViewProductClick}
                    />
                  </div>
                )})}
             {!isLoading && diagram?.parts?.length == 0 && <div className="px-8">No Parts Available</div>}   
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagramView;
